<template>
    <modal ref="modalDescuentos" titulo="Descuentos del beneficio" tamano="modal-lg" no-cancelar no-aceptar>
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col-10 justify-content-center px-0">
                <div class="row mx-0">
                    <div class="col">
                        <p class="text-general2 text-right mb-3 mb-3 f-18">
                            Por compras superiores a
                        </p>
                        <div v-for="(item,x) in descuentos" :key="x" class="row mx-0 mb-3">
                            <div class="col">
                                <p class="text-general text-right f-600 f-18">
                                    {{ separadorNumeroDecimales(item.desde) }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <p class="text-general2 mb-3 mb-3 ml-3 f-18">
                            Obtiene
                        </p>
                        <div v-for="(item,x) in descuentos" :key="x" class="row mx-0 mb-3">
                            <div class="col">
                                <p class="text-general f-600 f-18">
                                    {{ agregarSeparadoresNumero(item.descuento) }}% de descuento
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            descuentos: [],
            url_persona: 'https://st.depositphotos.com/2069323/2156/i/600/depositphotos_21568765-stock-photo-casual-businessman-pointing.jpg'
        }
    },
    methods: {
        toggle(descuentos){
            this.descuentos = descuentos;
            this.$refs.modalDescuentos.toggle();
        }
    }
}
</script>